import { useRouter } from 'next/router';
import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';

import { UserType } from '@nextTypes/user';
import { getABTestCookies } from '@nextUtils/abTestUtils';
import { trackMixpanelPageView } from '@nextUtils/trackingUtils';
import { ErrorTracking } from '@common/errorTracking';
import {
  defaultAvailability,
  useAvailableMedication,
} from '@hooks/useAvailableMedication';
import { MainContextType } from './MainContextType';

const initialState: MainContextType = {
  user: undefined,
  abTests: null,
  medicationAvailability: defaultAvailability,
  isAbTestVariant: () => false,
  setUser: () => undefined,
  setAbTests: () => undefined,
  fetchUser: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

const MainContext = createContext<MainContextType>(initialState);

const MainProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { locale, pathname, asPath } = useRouter();
  const hasInitialised = useRef(false);

  // Re-use initial state as defined above
  const [state, setState] = useState<MainContextType>({
    ...initialState,
  });

  const setUser = (user: UserType | null | undefined) => {
    setState(prevState => ({ ...prevState, user }));
  };

  const setAbTests = (abTests: MainContextType['abTests']) => {
    setState(prevState => ({ ...prevState, abTests }));
  };

  const logout = () =>
    new Promise<void>((resolve, reject) => {
      if (!state.user) {
        resolve();
        return;
      }

      fetch('/api/logout', {
        method: 'POST',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Logout call failed');
          }

          setUser(null);

          if (window.FS) {
            window.FS.clearUserCookie();
          }
          if (window.mixpanel?.reset) {
            window.mixpanel.reset();
          }
          resolve();
        })
        .catch(err => {
          ErrorTracking.track(err);
          reject(err);
        });
    });

  const fetchUser = useCallback(async () => {
    // Store pathname and search here in case
    // page changes before the user API call returns
    let user: UserType | null = null;

    const authResponse = await fetch('/api/v1/auth/check');
    const { isAuthenticated }: { isAuthenticated: boolean } =
      await authResponse.json();

    if (isAuthenticated) {
      const response = await fetch('/api/v2/user');

      const { user: userResponse } = await response.json();

      if (userResponse) {
        user = userResponse;

        if (window.mixpanel?.identify) {
          window.mixpanel.identify(userResponse.email);
        }

        if (window.ire) {
          window.ire('identify', { customerId: userResponse._id });
        }

        if (window.clarity) {
          window.clarity(
            'identify',
            userResponse.email,
            null,
            null,
            userResponse.email,
          );
        }
      }
    }

    setState(prevState => ({
      ...prevState,
      user,
    }));

    // The reason this is performed here is to ensure that
    // if a user is logged in, this first Mixpanel track event
    // happens AFTER they've been identified above
    // Track initial page view after user identification (if any)
    const { search } = window.location;
    if (!hasInitialised.current) {
      trackMixpanelPageView(asPath, search);
    }

    hasInitialised.current = true;
  }, [asPath]);

  useEffect(() => {
    if (hasInitialised.current) {
      const { search } = window.location;
      trackMixpanelPageView(asPath, search);
    }
  }, [asPath]);

  useEffect(() => {
    const abTests = getABTestCookies(locale);

    setState(prevState => ({
      ...prevState,
      abTests,
    }));

    fetchUser();
  }, [locale, pathname, fetchUser]);

  const isAbTestVariant = ({
    testID,
    variant,
  }: {
    testID: number | string;
    variant: number | string;
  }): boolean => {
    const foundTest = state.abTests?.[testID];
    return foundTest?.variant.toString() === variant.toString();
  };

  useAvailableMedication(setState);

  return (
    <MainContext.Provider
      value={{
        user: state.user,
        abTests: state.abTests,
        medicationAvailability: state.medicationAvailability,
        isAbTestVariant,
        setUser,
        setAbTests,
        fetchUser,
        logout,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

const MainConsumer = MainContext.Consumer;

export default MainProvider;
export { MainConsumer, MainContext };
