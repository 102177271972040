import { ChangeEvent } from 'react';

import { HeightUnitType, WeightUnitType } from '@nextTypes/units';

export const HEALTH_CONDITIONS = [
  'ADHD',
  'Anxiety',
  'Anxiety or depression',
  'Cancer',
  'Chronic malabsorption syndrome',
  'Coeliac disease',
  'Depression',
  'Diabetic retinopathy',
  'Eating disorder',
  'Gallbladder cancer',
  'Gallbladder problems',
  'Gastrointestinal disorders',
  'Heart failure',
  'Hypothyroidism',
  'Impaired Liver',
  'Inflammatory bowel disease',
  'Irritable bowel syndrome',
  'Kidney disease',
  'MEN-2',
  'Menopause or hormone replacement therapy',
  'Multiple Endocrine Neoplasia syndrome type 2 (MEN 2)',
  'No Gallbladder',
  'Osteoarthritis',
  'Other',
  'Pancreatitis',
  'Pancreatic cancer',
  'Polycystic ovary syndrome',
  'Prediabetes/Non-diabetic hyperglycaemia',
  'Thyroid cancer',
  'Type 1 diabetes',
  'Type 2 diabetes',
  'None',
] as const;

export type HealthConditionsType = (typeof HEALTH_CONDITIONS)[number];

/**
 * Array of value / text pairs to use as quiz options in the comorbidities quiz screen
 */
export const HEALTH_CONDITIONS_COMORBIDITIES = [
  { value: 'Hypertension', text: 'High blood pressure (hypertension)' },
  {
    value: 'High cholesterol (dyslipidemia)',
    text: 'High cholesterol (dyslipidemia) - over 5mmol/L',
  },
  { value: 'Cardiovascular disease', text: 'Cardiovascular disease' },
  { value: 'Chronic back pain', text: 'Chronic back pain' },
  {
    value: 'GORD (Gastro-oesophageal reflux disease)',
    text: 'GORD (Gastro-oesophageal reflux disease)',
  },
  {
    value: 'Non-alcoholic fatty liver disease',
    text: 'Non-alcoholic fatty liver disease',
  },
  {
    value: 'NASH (Non-alcoholic steatohepatitis)',
    text: 'NASH (Non-alcoholic steatohepatitis)',
  },
  {
    value: 'Obesity hypoventilation syndrome or Pickwickian syndrome',
    text: 'Obesity hypoventilation syndrome or Pickwickian syndrome',
  },
  { value: 'Sleep apnoea', text: 'Sleep apnoea' },
  { value: 'Venous stasis disease', text: 'Venous stasis disease' },
  {
    value: 'None',
    text: "I don't have any of these health conditions",
  },
] as const;

export type HealthConditionsComorbiditiesType =
  | (typeof HEALTH_CONDITIONS_COMORBIDITIES)[number]['value']
  | Extract<
      HealthConditionsType,
      'Osteoarthritis' | 'Polycystic ovary syndrome'
    >;

export type QuizWarningsType = readonly {
  text: string;
  showOnValue: string | boolean;
  canProceed: boolean;
}[];

export type OptionsType = {
  text: string;
  value?: string | boolean;
  modal?: {
    question: string;
    placeholder: string;
  };
}[];

export type DropdownOptionsType<T extends string = string> = {
  text: string;
  value: T;
}[];

export type QuizOptionsType<T = OptionsType> = {
  title: string;
  question: keyof OldQuizAnswersType;
  isMultiSelect?: boolean;
  subtitle?: string;
  explainer?: string;
  options: T;
  parent?: { question: QuizOptionsType; showOnValue: string | boolean };
  warnings?: QuizWarningsType;
  areCoachAndSelfLedOptionsAvailable?: boolean;
  isArray?: boolean;
};

export type HeightAndWeightFormType = {
  heightUnit?: HeightUnitType;
  weightUnit?: WeightUnitType;
  age?: number;
  birthday?: Date;
  birthdayDay?: number;
  birthdayMonth?: number;
  birthdayYear?: number;
  heightCm?: number;
  heightFT?: number;
  heightINCH?: number;
  weightKg?: number;
  weightLBS?: number;
  weightST?: number;
  hasWeightGoalInMind?: boolean;
  weightLossGoalKg?: number;
  weightLossGoalSt?: number;
  weightLossGoalLbs?: number;
};

export type HeightAndWeightUnitDetailsType = {
  title: string;
  unit: {
    metric: string;
    imperialLarge: string;
    imperialSmall: string;
  };
  fieldName: {
    metric: string;
    imperialLarge: string;
    imperialSmall: string;
  };
  onChange: {
    metric: (event: ChangeEvent<HTMLInputElement>) => void;
    imperialLarge: (event: ChangeEvent<HTMLInputElement>) => void;
    imperialSmall: (event: ChangeEvent<HTMLInputElement>) => void;
  };
  value: {
    metric: number;
    imperialLarge: number;
    imperialSmall: number;
  };
};

export type GPContactInfo = {
  gpName: string;
  gpAddress: string;
};

export type PersonalHabitsType =
  | 'alwaysThinkingAboutFood'
  | 'avoidingBadFoods'
  | 'foodChoicesEitherGoodOrBad'
  | 'lowMoodDueToWeight'
  | 'needToBePerfectFirst'
  | 'neverHappyWithAchievedWeightLoss'
  | 'none'
  | 'notReachingHighStandards'
  | 'sayingNoToOthers'
  | 'slipUpAndThenOverEating'
  | 'strugglingToPutMyselfFirst';

export type CommonHabitType =
  | 'stress'
  | 'bored'
  | 'snacking'
  | 'alcohol'
  | 'other';

export type DiabetesStatusType =
  | 'type1'
  | 'type2'
  | 'prediabetic'
  | 'familyHistory'
  | 'none';

export type Type2DiabetesMedicationType =
  | 'insulin'
  | 'metformin'
  | 'sulfonylurea'
  | 'glp1'
  | 'gliptins'
  | 'other'
  | 'none';

export type PrioritiesType =
  | 'conceive'
  | 'fitIntoMyClothes'
  | 'healthCondition'
  | 'mentalWellbeing'
  | 'overallHealth'
  | 'planningMeals'
  | 'preMenopausalConfidence'
  | 'whyBehindMyFoodChoices';

export type PregnancyStatusType =
  | 'pregnant'
  | 'breastfeeding'
  | 'problemsConceiving'
  | 'planningForPregnancy'
  | 'tryingToConceive'
  | 'none';

export type EatingDisorderDiagnosisType =
  | 'yesPastEatingDisorder'
  | 'yesCurrentEatingDisorder'
  | 'noEatingDisorder';

export type OpenToMedicationType =
  | 'injectedMedication'
  | 'nonInjectedMedication' // removed from quiz on 25/01/24 to test if it impacts conversion
  | 'noMedication';

export enum DIABETES_COMPLICATIONS {
  Ketoacidosis = 'ketoacidosis',
  Hypoglycemia = 'hypoglycemia',
  Retinopathy = 'retinopathy',
  KidneyProblems = 'kidneyProblems',
  FootProblems = 'footProblems',
  NerveDamage = 'nerveDamage',
  None = 'none',
}

export enum DISABILITY_STATUS {
  YES = 'Yes',
  NO = 'No',
  NOT_KNOWN = 'Not Known',
  NOT_STATED = 'Not Stated',
  PREFER_NOT_TO_SAY = 'Prefer not to say',
}

export enum DISABILITY_TYPE {
  BEHAVIOUR_AND_EMOTIONAL = 'Behaviour and Emotional',
  HEARING = 'Hearing',
  MANUAL_DEXTERITY = 'Manual Dexterity',
  LEARNING_DISABILITY = 'Memory or ability to concentrate, learn or understand (Learning Disability)',
  MOBILITY_AND_GROSS_MOTOR = 'Mobility and Gross Motor',
  PERCEPTION_OF_PHYSICAL_DANGER = 'Perception of Physical Danger',
  PERSONAL_SELF_CARE_AND_CONTINENCE = 'Personal, Self-Care and Continence',
  PROGRESSIVE_CONDITIONS_AND_PHYSICAL_HEALTH = 'Progressive Conditions and Physical Health (such as HIV, cancer, multiple sclerosis, fits etc.)',
  SIGHT = 'Sight',
  SPEECH = 'Speech',
  OTHER = 'Other',
  NOT_STATED = 'Not Stated (person asked but declined to provide a response)',
}

/**
 * Currently only used in the consumer MSP quiz
 */
export enum ETHNICITY {
  WHITE_BRITISH = 'WhiteBritish',
  WHITE_IRISH = 'WhiteIrish',
  WHITE_GYPSY = 'WhiteGypsy',
  WHITE_OTHER = 'WhiteOther',
  MIXED_WHITE_BLACK_CARIBBEAN = 'MixedWhiteBlackCaribbean',
  MIXED_WHITE_BLACK_AFRICAN = 'MixedWhiteBlackAfrican',
  MIXED_WHITE_BLACK_ASIAN = 'MixedWhiteAsian',
  MIXED_WHITE_ARAB_MIDDLE_EASTERN = 'MixedArabMiddleEastern',
  MIXED_OTHER = 'MixedOther',
  ARAB_MIDDLE_EASTERN = 'ArabMiddleEastern',
  ASIAN_CHINESE = 'AsianChinese',
  ASIAN_INDIAN = 'AsianIndian',
  ASIAN_PAKISTANI = 'AsianPakistani',
  ASIAN_BANGLADESHI = 'AsianBangladeshi',
  ASIAN_OTHER = 'AsianOther',
  BLACK_CARIBBEAN = 'BlackCaribbean',
  BLACK_AFRICAN = 'BlackAfrican',
  BLACK_OTHER = 'BlackOther',
  OTHER = 'Other',
  NOT_KNOWN = 'NotKnown',
}

export type OldQuizAnswersType = {
  hasTakenQuizBefore?: string;
  motivation?: string;
  challenge?: string;
  recentChallenges?: {
    emotionalEating?: boolean;
    time?: boolean;
    foodChoices?: boolean;
    stickingToWeightLoss?: boolean;
    lackOfSupport?: boolean;
    timeToPutMyselfFirst?: boolean;
    scientificApproach?: boolean;
    shameFreeCommunity?: boolean;
    solveRelationshipWithFood?: boolean;
    ivf?: boolean;
    busySocialLife?: boolean;
  };
  priorities?: Partial<Record<PrioritiesType, boolean>>;
  foodFeelings?: {
    control?: boolean;
    bored?: boolean;
    notHungry?: boolean;
    moreSnacks?: boolean;
    lessHealthy?: boolean;
    moreHealthy?: boolean;
    confused?: boolean;
  };
  whoDoYouCookFor?: 'myself' | 'partnerOrFamilyMembers' | 'someoneCookingForMe';
  prioritiesSingleChoice?: string;
  goals?: string;
  healthConditions?: HealthConditionsType[];
  healthConditionsComorbidities?: HealthConditionsComorbiditiesType[];
  healthConditionsComorbiditiesDetails?: Partial<
    Record<HealthConditionsComorbiditiesType, string>
  >;
  healthConditionsOpenText?: string;
  pregnancy?: PregnancyStatusType[];
  wantsWeightLossGoal?: boolean;
  goalKg?: number;
  percentageGoal?: number;
  gender?: 'male' | 'female';
  hasHealthCondition?: boolean;
  hasOtherHealthConditions?: boolean;
  hasWeightGoalInMind?: boolean;
  dietaryPreferences?: string[];
  personalHabits?: PersonalHabitsType[];
  goalKgLongTerm?: number;
  takesMedication?: boolean;
  currentMedication?: string;
  tripwireOfferSelectedPrice?: string;
  willingToPayAnswer?: 'Yes' | 'No' | 'Risk-free trial';
  weightUnit?: WeightUnitType;
  heightUnit?: HeightUnitType;
  age?: number;
  birthday?: Date;
  weightKg?: number;
  weightST?: number;
  weightLBS?: number;
  heightCm?: number;
  heightFT?: number;
  heightINCH?: number;
  weightLossGoalKg?: number;
  weightLossGoalSt?: number;
  weightLossGoalLbs?: number;
  firstName?: string;
  regularFoods?: string[];
  dailyExperience?: string[];
  commonHabbit?: CommonHabitType;
  hasAllergies?: boolean;
  allergies?: string;
  diabetesStatus?: DiabetesStatusType;
  type2DiabetesMedication?: Partial<
    Record<Type2DiabetesMedicationType, boolean>
  >;
  openToMedication?: OpenToMedicationType;
  hasDiabetesDoctorsAppt?: boolean;
  diabetesMedicationPrescriber?: string;
  diabetesManagement?: Record<string, boolean>;
  diabetesComplications?: Partial<Record<`${DIABETES_COMPLICATIONS}`, boolean>>;
} & Partial<GPContactInfo>;

export type ValidationHandleChangeType = (
  values: HeightAndWeightFormType,
) => void;

export type ValidationHandleBlurType = (field: string) => void;
export type QuizWillingToPayAnswerType = 'Yes' | 'No' | 'Risk-free trial';

/**
 * Array of value / text pairs to use as quiz options in the comorbidities quiz screen
 */
export const VITALITY_HEALTH_CONDITIONS_COMORBIDITIES = [
  { value: 'Hypertension', text: 'High blood pressure (hypertension)' },
  {
    value: 'High cholesterol (dyslipidemia)',
    text: 'High cholesterol (dyslipidemia) - over 5mmol/L',
  },
  { value: 'Coronary artery disease', text: 'Coronary artery disease' },
  {
    value: 'Non-alcoholic fatty liver disease',
    text: 'Non-alcoholic fatty liver disease',
  },
  {
    value: 'NASH (Non-alcoholic steatohepatitis)',
    text: 'NASH (Non-alcoholic steatohepatitis)',
  },
  {
    value: 'Obesity hypoventilation syndrome or Pickwickian syndrome',
    text: 'Obesity hypoventilation syndrome or Pickwickian syndrome',
  },
  { value: 'Obstructive sleep apnoea', text: 'Obstructive sleep apnoea' },
  { value: 'Venous stasis disease', text: 'Venous stasis disease' },
  {
    value: 'None',
    text: "I don't have any of these health conditions",
  },
] as const;

export type VitalityHealthConditionsComorbiditiesType =
  | (typeof VITALITY_HEALTH_CONDITIONS_COMORBIDITIES)[number]['value']
  | Extract<
      HealthConditionsType,
      'Osteoarthritis' | 'Polycystic ovary syndrome'
    >;
