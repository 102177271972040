import {
  HeightUnitType,
  WeightUnitType as GenericWeightUnitType,
} from '@nextTypes/units';
import {
  DiabetesStatusType,
  HealthConditionsType,
  PregnancyStatusType,
  VitalityHealthConditionsComorbiditiesType,
} from '@nextTypes/quiz';

export type WeightUnitType = Omit<GenericWeightUnitType, 'lbs'>;

export type VitalityQuizStateType = {
  priorities: string[];
  recentChallenges: string[];
  personalHabits: string[];
  goal: string;
  gender: string;
  dateOfBirth?: Date;
  heightUnit: HeightUnitType;
  heightCm: number;
  heightFT: number;
  heightINCH: number;
  weightUnit: WeightUnitType;
  weightKg: number;
  weightLBS: number;
  weightST: number;
  weightGainLifeEvents: string[];
  howImportantIsWeightToYou: string;
  howDoYouLikeYourGoals: string;
  pregnancy: PregnancyStatusType[];
  diabetesStatus?: DiabetesStatusType;
  diabetesManagement: string[];
  healthConditions: HealthConditionsType[];
  healthConditionsComorbidities: VitalityHealthConditionsComorbiditiesType[];
  type2DiabetesMedications: string[];
  diabetesMedicationPrescriber: string;
  hasDiabetesDoctorsAppointment?: boolean;
  diabetesComplications: string[];
  hasOtherHealthConditions?: boolean;
  hasDietaryPreferences?: boolean;
  dietaryPreferences: string[];
  hasSupportSystem: string;
  healthConditionsText: string;
  healthConditionsComorbiditiesDetails: {
    key: VitalityHealthConditionsComorbiditiesType;
    value: string;
  }[];
  takesMedication?: boolean;
  goalWeightKg: number;
  goalWeightLBS: number;
  goalWeightST: number;
  percentageGoals: number;
  currentMedication: string;
  hasAllergies?: boolean;
  allergyDetails: string;
  openToMedication: string;
  hasWeightLossGoal?: boolean;
  weightLossGoalKg: number;
  weightLossGoalLBs: number;
  weightLossGoalST: number;
  foodFeelings: string[];
  weightLossApproach?: string;
  healthyHabitsSupport?: string;
  biggestConcern: string;
  habitsRole: string;
  lifestyleChange: string;
  lifestyleVision: string;
  medicationView: string;
  pastExperience: string;
  primaryMotivation: string;
  scienceInterest: string;
  supportType: string;
  timeline: string;
  alcoholConsumption: string;
  eatingOutFrequency: string;
  eatingPattern: string;
  leisureTime: string;
  mealPreparationFrequency: string;
  physicalActivityLevel: string;
  sleepHabits: string;
  socialSupport: string;
  stressManagement: string;
  workEnvironmentImpact: string;
  primaryEthnicity: string;
  secondaryEthnicity: string;
  religion: string;
  hasSelectedMedicatedProgramme: boolean;
  selectedMedication: string;
  firstName: string;
  lastName: string;
  hasTakenWeightLossMedicine?: boolean;
  weightLossMedicinesTaken: string[];
  email: string;
  phoneNumber: string;
  vitalityMembershipNumber: string;
  smokingStatus: string;
  selectedStartDate: string;
  addressFirstLine: string;
  addressSecondLine: string;
  addressCity: string;
  addressPostCode: string;
};

export type VitalityQuizEligiblityAnswers = Pick<
  VitalityQuizStateType,
  | 'dateOfBirth'
  | 'weightKg'
  | 'heightCm'
  | 'gender'
  | 'pregnancy'
  | 'healthConditions'
  | 'personalHabits'
  | 'healthConditionsComorbidities'
  | 'diabetesStatus'
  | 'diabetesManagement'
  | 'hasDiabetesDoctorsAppointment'
  | 'diabetesComplications'
  | 'diabetesMedicationPrescriber'
  | 'type2DiabetesMedications'
  | 'openToMedication'
  | 'email'
  | 'phoneNumber'
  | 'vitalityMembershipNumber'
>;

export const initialState: VitalityQuizStateType = {
  priorities: [],
  recentChallenges: [],
  personalHabits: [],
  goal: '',
  gender: '',
  dateOfBirth: undefined,
  heightUnit: 'ft',
  heightCm: 0,
  heightFT: 0,
  heightINCH: 0,
  weightUnit: 'kg',
  weightKg: 0,
  weightLBS: 0,
  weightST: 0,
  weightGainLifeEvents: [],
  howImportantIsWeightToYou: '',
  howDoYouLikeYourGoals: '',
  pregnancy: [],
  diabetesStatus: undefined,
  diabetesManagement: [],
  healthConditions: [],
  healthConditionsComorbidities: [],
  type2DiabetesMedications: [],
  diabetesMedicationPrescriber: '',
  hasDiabetesDoctorsAppointment: undefined,
  diabetesComplications: [],
  hasOtherHealthConditions: undefined,
  hasDietaryPreferences: undefined,
  dietaryPreferences: [],
  hasSupportSystem: '',
  healthConditionsText: '',
  healthConditionsComorbiditiesDetails: [],
  takesMedication: undefined,
  goalWeightKg: 0,
  goalWeightLBS: 0,
  goalWeightST: 0,
  percentageGoals: 0,
  currentMedication: '',
  hasAllergies: undefined,
  openToMedication: '',
  allergyDetails: '',
  hasWeightLossGoal: undefined,
  weightLossGoalKg: 0,
  weightLossGoalLBs: 0,
  weightLossGoalST: 0,
  foodFeelings: [],
  weightLossApproach: undefined,
  healthyHabitsSupport: undefined,
  biggestConcern: '',
  habitsRole: '',
  lifestyleChange: '',
  lifestyleVision: '',
  medicationView: '',
  pastExperience: '',
  primaryMotivation: '',
  scienceInterest: '',
  supportType: '',
  timeline: '',
  alcoholConsumption: '',
  eatingOutFrequency: '',
  eatingPattern: '',
  leisureTime: '',
  mealPreparationFrequency: '',
  physicalActivityLevel: '',
  sleepHabits: '',
  socialSupport: '',
  stressManagement: '',
  workEnvironmentImpact: '',
  primaryEthnicity: '',
  secondaryEthnicity: '',
  religion: '',
  hasSelectedMedicatedProgramme: false,
  selectedMedication: 'Mounjaro',
  firstName: '',
  lastName: '',
  hasTakenWeightLossMedicine: undefined,
  weightLossMedicinesTaken: [],
  email: '',
  phoneNumber: '',
  vitalityMembershipNumber: '',
  smokingStatus: '',
  selectedStartDate: '',
  addressFirstLine: '',
  addressSecondLine: '',
  addressCity: '',
  addressPostCode: '',
};
