import { VitalityQuizStateType } from './state';
import { VitalityQuizActionType } from './actions';

export const initReducerState = (
  props: VitalityQuizStateType,
): VitalityQuizStateType => props;

export type ReducerState = ReturnType<typeof initReducerState>;

// Personal Information Reducer
const personalInfoReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_FIRST_NAME':
      return { ...state, firstName: action.payload };
    case 'SET_LAST_NAME':
      return { ...state, lastName: action.payload };
    case 'SET_GENDER':
      return { ...state, gender: action.payload };
    case 'SET_DATE_OF_BIRTH':
      return { ...state, dateOfBirth: action.payload };
    case 'SET_PRIMARY_ETHNICITY':
      return { ...state, primaryEthnicity: action.payload };
    case 'SET_PREGNANCY':
      return { ...state, pregnancy: action.payload };
    case 'SET_SECONDARY_ETHNICITY':
      return { ...state, secondaryEthnicity: action.payload };
    case 'SET_RELIGION':
      return { ...state, religion: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_PHONE_NUMBER':
      return { ...state, phoneNumber: action.payload };
    case 'SET_VITALITY_MEMBERSHIP_NUMBER':
      return { ...state, vitalityMembershipNumber: action.payload };
    default:
      return state;
  }
};

// Physical Measurements Reducer
const measurementsReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_HEIGHT_UNIT':
      return { ...state, heightUnit: action.payload };
    case 'SET_HEIGHT':
      return {
        ...state,
        heightCm: action.payload.cm,
        heightFT: action.payload.ft,
        heightINCH: action.payload.inch,
      };
    case 'SET_WEIGHT_UNIT':
      return { ...state, weightUnit: action.payload };
    case 'SET_WEIGHT':
      return {
        ...state,
        weightKg: action.payload.kg,
        weightLBS: action.payload.lbs,
        weightST: action.payload.st,
      };
    case 'SET_GOAL_WEIGHTS':
      return {
        ...state,
        goalWeightKg: action.payload.kg,
        goalWeightLBS: action.payload.lbs,
        goalWeightST: action.payload.st,
      };
    case 'SET_WEIGHT_LOSS_GOAL':
      return {
        ...state,
        weightLossGoalKg: action.payload.kg,
        weightLossGoalLBs: action.payload.lbs,
        weightLossGoalST: action.payload.st,
      };
    default:
      return state;
  }
};

// Health Conditions Reducer
const healthConditionsReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_HEALTH_CONDITIONS':
      return { ...state, healthConditions: action.payload };
    case 'SET_HEALTH_CONDITIONS_COMORBIDITIES': {
      const newHealthConditionsComorbidities = action.payload;
      const updatedHealthConditionsComorbiditiesDetails =
        state.healthConditionsComorbiditiesDetails.filter(comorbidity =>
          newHealthConditionsComorbidities.includes(comorbidity.key),
        );
      return {
        ...state,
        healthConditionsComorbidities: newHealthConditionsComorbidities,
        healthConditionsComorbiditiesDetails:
          updatedHealthConditionsComorbiditiesDetails,
      };
    }
    case 'SET_HEALTH_CONDITIONS_TEXT':
      return { ...state, healthConditionsText: action.payload };
    case 'SET_COMORBIDITY_DETAILS':
      return { ...state, healthConditionsComorbiditiesDetails: action.payload };
    case 'SET_HAS_OTHER_HEALTH_CONDITIONS':
      return { ...state, hasOtherHealthConditions: action.payload };
    default:
      return state;
  }
};

// Diabetes Related Reducer
const diabetesReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_DIABETES_STATUS':
      return { ...state, diabetesStatus: action.payload };
    case 'SET_DIABETES_MANAGEMENT':
      return { ...state, diabetesManagement: action.payload };
    case 'SET_TYPE_2_DIABETES_MEDICATIONS':
      return { ...state, type2DiabetesMedications: action.payload };
    case 'SET_DIABETES_MEDICATION_PRESCRIBER':
      return { ...state, diabetesMedicationPrescriber: action.payload };
    case 'SET_HAS_DIABETES_DOCTORS_APPOINTMENT':
      return { ...state, hasDiabetesDoctorsAppointment: action.payload };
    case 'SET_DIABETES_COMPLICATIONS':
      return { ...state, diabetesComplications: action.payload };
    default:
      return state;
  }
};

// Lifestyle and Habits Reducer
const lifestyleReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_PERSONAL_HABITS':
      return { ...state, personalHabits: action.payload };
    case 'SET_ALCOHOL_CONSUMPTION':
      return { ...state, alcoholConsumption: action.payload };
    case 'SET_EATING_OUT_FREQUENCY':
      return { ...state, eatingOutFrequency: action.payload };
    case 'SET_EATING_PATTERN':
      return { ...state, eatingPattern: action.payload };
    case 'SET_LEISURE_TIME':
      return { ...state, leisureTime: action.payload };
    case 'SET_MEAL_PREPARATION_FREQUENCY':
      return { ...state, mealPreparationFrequency: action.payload };
    case 'SET_PHYSICAL_ACTIVITY_LEVEL':
      return { ...state, physicalActivityLevel: action.payload };
    case 'SET_SLEEP_HABITS':
      return { ...state, sleepHabits: action.payload };
    case 'SET_STRESS_MANAGEMENT':
      return { ...state, stressManagement: action.payload };
    case 'SET_WORK_ENVIRONMENT_IMPACT':
      return { ...state, workEnvironmentImpact: action.payload };
    case 'SET_SMOKING_STATUS':
      return { ...state, smokingStatus: action.payload };
    case 'SET_HAS_DIETARY_PREFERENCES':
      return { ...state, hasDietaryPreferences: action.payload };
    case 'SET_DIETARY_PREFERENCES':
      return { ...state, dietaryPreferences: action.payload };
    default:
      return state;
  }
};

// Goals and Preferences Reducer
const goalsReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_PRIORITIES':
      return { ...state, priorities: action.payload };
    case 'SET_GOAL':
      return { ...state, goal: action.payload };
    case 'SET_HOW_DO_YOU_LIKE_YOUR_GOALS':
      return { ...state, howDoYouLikeYourGoals: action.payload };
    case 'SET_PERCENTAGE_GOAL':
      return { ...state, percentageGoals: action.payload };
    case 'SET_HAS_WEIGHT_LOSS_GOAL':
      return { ...state, hasWeightLossGoal: action.payload };
    case 'SET_WEIGHT_LOSS_APPROACH':
      return { ...state, weightLossApproach: action.payload };
    case 'SET_GOAL_WEIGHT_KG':
      return { ...state, goalWeightKg: action.payload };
    case 'SET_PERCENTAGE_GOALS':
      return { ...state, percentageGoals: action.payload };
    default:
      return state;
  }
};

// Medication and Allergies Reducer
const medicationReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_TAKES_MEDICATION':
      return { ...state, takesMedication: action.payload };
    case 'SET_CURRENT_MEDICATION':
      return { ...state, currentMedication: action.payload };
    case 'SET_HAS_ALLERGIES':
      return { ...state, hasAllergies: action.payload };
    case 'SET_ALLERGY_DETAILS':
      return { ...state, allergyDetails: action.payload };
    case 'SET_OPEN_TO_MEDICATION':
      return { ...state, openToMedication: action.payload };
    case 'SET_HAS_SELECTED_MEDICATED_PROGRAMME':
      return { ...state, hasSelectedMedicatedProgramme: action.payload };
    case 'SET_SELECTED_MEDICATION':
      return { ...state, selectedMedication: action.payload };
    case 'SET_HAS_TAKEN_WEIGHT_LOSS_MEDICINE':
      return { ...state, hasTakenWeightLossMedicine: action.payload };
    case 'SET_WEIGHT_LOSS_MEDICINES_TAKEN':
      return { ...state, weightLossMedicinesTaken: action.payload };
    default:
      return state;
  }
};

// Miscellaneous Reducer
const miscReducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  switch (action.type) {
    case 'SET_ALL':
      return { ...state, ...action.payload };
    case 'SET_SELECTED_START_DATE':
      return { ...state, selectedStartDate: action.payload };

    default:
      return state;
  }
};

// Main reducer that combines all the smaller reducers
export const reducer = (
  state: VitalityQuizStateType,
  action: VitalityQuizActionType,
): VitalityQuizStateType => {
  let newState = state;

  newState = personalInfoReducer(newState, action);
  newState = measurementsReducer(newState, action);
  newState = healthConditionsReducer(newState, action);
  newState = diabetesReducer(newState, action);
  newState = lifestyleReducer(newState, action);
  newState = goalsReducer(newState, action);
  newState = medicationReducer(newState, action);
  newState = miscReducer(newState, action);

  return newState;
};
