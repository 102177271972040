import { NextPage } from 'next';
import { ReactElement, ReactNode, useEffect } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { NextIntlProvider } from 'next-intl';
import { ErrorBoundary, init as sentryInit } from '@sentry/nextjs';
import localFont from 'next/font/local';

import MainProvider from '@components/context/MainProvider';
import { RestartProvider } from '@components/context/Restart/RestartProvider';
import NHSSignupProvider from '@components/context/NHSSignupProvider';
import VitalitySignupProvider from '@components/context/VitalityProvider';

import {
  NEXTJS_DEFAULT_LOCALE,
  NEXTJS_LOCALES_TO_LANG,
} from '@constants/internationalConstants';

import '../common/global.css';
import { AppEnvStates } from '../../src/constants';
import VitalityQuizProvider from '../components/context/VitalityQuizProvider';

export const cera = localFont({
  src: [
    {
      path: '../../assets/fonts/cera/cerapro-regular.otf',
      weight: '400',
    },
    {
      path: '../../assets/fonts/cera/cerapro-medium.otf',
      weight: '500',
    },
  ],
  variable: '--font-cera',
});

export const firsNeue = localFont({
  src: [
    {
      path: '../../assets/fonts/firsNeue/TTFirsNeue-Medium.otf',
      weight: '500',
    },
    {
      path: '../../assets/fonts/firsNeue/TTFirsNeue-DemiBold.otf',
      weight: '600',
    },
  ],
  variable: '--font-firs',
});

/**
 *
 * The 'locales' that we use (i.e. 'uk', 'us') are used to set the 'lang' attribute on the page on clientside transitions
 * This results in the page language being set to 'uk' (Ukraine) on page transitions - and the user will be asked 'do you want to translate this page?'
 *
 * We run this in both onRouteChangeComplete and when router.isReady. Setting it on router.isReady is because
 * if a page transition happens with a query string, the _document is not re-run by NextJS, so the locale is set incorrectly
 */
const setHTMLLang = (locale: string): void => {
  const lang = NEXTJS_LOCALES_TO_LANG[locale];
  if (document.documentElement.lang !== lang) {
    document.documentElement.lang = lang;
  }
};

/**
 * The 'getLayout' pattern is a way to define layouts on a per-page basis in Next.js.
 * This pattern allows you to wrap each page in a layout component, which can be useful for maintaining
 * consistent structures across different pages, such as headers, footers, or sidebars.
 * We can also use this pattern to wrap pages in a layout that is specific to the page's parent directory,
 * instead of having to wrap each page individually.
 *
 * In this file, a type `NextPageWithLayout` is defined. This type extends the `NextPage` type from Next.js
 * and adds an optional `getLayout` function. This function, if provided, takes a `ReactElement` (the page itself)
 * and a `pathname` string, and returns a `ReactNode` (the page wrapped in a layout).
 *
 * The `getLayout` function is then used in the `MyApp` component to wrap the `Component` (the current page) in its layout,
 * if one is defined in the page component. If no layout is defined, the page is rendered as-is.
 *
 * To use this pattern in a page, you would define a `getLayout` function on the page component e.g. `pages/index.tsx`:
 * ```
 * const getLayout = (page: ReactElement) => <Layout>{page}</Layout>;
 * const IndexPage: NextPageWithLayout = () => <>Index page</>;
 * IndexPage.getLayout = getLayout;
 * export default IndexPage;
 * ```
 *
 * For more information on this pattern, refer to the Next.js documentation on pages and layouts:
 * https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts
 */
type NextPageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pathname: string) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({
  Component,
  pageProps,
}: AppPropsWithLayout): JSX.Element {
  const getLayout = Component.getLayout ?? (page => page);

  const {
    events,
    locale = NEXTJS_DEFAULT_LOCALE,
    isReady,
    pathname,
  } = useRouter();

  // This useEffect runs client-side only
  // and only on first page load - not on page transitions
  useEffect(() => {
    // Don't initialise Sentry in local or cypress environments
    const enableSentry =
      !!process.env.APP_ENV &&
      ![
        AppEnvStates.LOCAL.toString(),
        AppEnvStates.CYPRESS.toString(),
      ].includes(process.env.APP_ENV);

    sentryInit({
      dsn: 'https://391211233cdd4f1e802a16d64a716815@o438436.ingest.sentry.io/5841009', // sn-nextjs DSN
      tracesSampleRate:
        process.env.APP_ENV === AppEnvStates.PRODUCTION ? 0.1 : 1,
      environment: process.env.APP_ENV,
      enabled: enableSentry,
    });
  }, []);

  useEffect(() => {
    if (isReady) {
      setHTMLLang(locale);
    }
  }, [isReady, locale]);

  useEffect(() => {
    // Runs on client-side only on page change (i.e. during page transitions)
    const onRouteChangeComplete = () => {
      setHTMLLang(locale);
    };

    events.on('routeChangeComplete', onRouteChangeComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [events, locale]);

  return (
    <ErrorBoundary>
      <NextIntlProvider messages={pageProps.messages}>
        <MainProvider>
          <RestartProvider>
            <NHSSignupProvider>
              <VitalityQuizProvider>
                <VitalitySignupProvider>
                  {getLayout(
                    <div
                      // This applies two font variables - and then sets a default page font of Cera Pro
                      className={`${cera.variable} ${firsNeue.variable} font-cera`}
                      id="__font-container"
                    >
                      <Component {...pageProps} />
                    </div>,
                    pathname,
                  )}
                </VitalitySignupProvider>
              </VitalityQuizProvider>
            </NHSSignupProvider>
          </RestartProvider>
        </MainProvider>
      </NextIntlProvider>
    </ErrorBoundary>
  );
}
