import { getBMI } from '../../../src/utils/numberUtils';
import { QuizStateType } from '../../components/Quiz/state/state';
import {
  EligibilityCriteria,
  EligibilityResult,
  reduceEligibilityCriteria,
} from '../../components/Quiz/QuizUtils';
import {
  HealthConditionsComorbiditiesType,
  VITALITY_HEALTH_CONDITIONS_COMORBIDITIES,
  VitalityHealthConditionsComorbiditiesType,
} from '../../types/quiz';
import {
  GLP1_ELIGIBILITY_CRITERIA,
  getGLP1BaseCriteria,
} from './baseEligibility';
import { VitalityQuizStateType } from '../../components/VitalityQuiz/state/state';

export const GLP1_VALID_COMORBIDITIES: HealthConditionsComorbiditiesType[] = [
  'Cardiovascular disease',
  'Chronic back pain',
  'Non-alcoholic fatty liver disease',
  'GORD (Gastro-oesophageal reflux disease)',
  'Hypertension',
  'High cholesterol (dyslipidemia)',
  'NASH (Non-alcoholic steatohepatitis)',
  'Obesity hypoventilation syndrome or Pickwickian syndrome',
  'Osteoarthritis',
  'Polycystic ovary syndrome',
  'Sleep apnoea',
  'Venous stasis disease',
];

/**
 * Determines if a user is eligible for the GLP1 programme based on
 * having a BMI between 27 and 30. (User must also have a comorbidity
 * if their BMI falls in this range)
 *
 * @param {number | null} bmi - User's bmi based on quiz answer
 * @returns {boolean} true if the user's bmi is >= 27 and < 30, false otherwise
 */
export const meetsGLP1BMICriteriaWithComorbidity = (
  bmi: number | null,
): boolean =>
  !!bmi &&
  bmi >= GLP1_ELIGIBILITY_CRITERIA.bmiWithComorbidity.min &&
  bmi < GLP1_ELIGIBILITY_CRITERIA.bmiWithComorbidity.max;

export const getWeightRelatedComorbidities = (
  answers: Pick<
    QuizStateType,
    'healthConditions' | 'healthConditionsComorbidities'
  >,
): HealthConditionsComorbiditiesType[] => {
  const combinedHealthConditions = [
    ...(answers.healthConditions || []),
    ...(answers.healthConditionsComorbidities || []),
  ];

  return (
    (combinedHealthConditions.filter(condition =>
      GLP1_VALID_COMORBIDITIES.includes(
        condition as HealthConditionsComorbiditiesType,
      ),
    ) as HealthConditionsComorbiditiesType[]) || []
  );
};

export const getVitalityWeightRelatedComorbidities = (
  answers: Pick<
    VitalityQuizStateType,
    'healthConditions' | 'healthConditionsComorbidities'
  >,
): VitalityHealthConditionsComorbiditiesType[] => {
  const combinedHealthConditions = [
    ...(answers.healthConditions || []),
    ...(answers.healthConditionsComorbidities || []),
  ];

  const validComorbidities = VITALITY_HEALTH_CONDITIONS_COMORBIDITIES.filter(
    option => option.value !== 'None',
  ).map(option => option.value) as VitalityHealthConditionsComorbiditiesType[];

  return combinedHealthConditions.filter(condition =>
    validComorbidities.includes(
      condition as VitalityHealthConditionsComorbiditiesType,
    ),
  ) as VitalityHealthConditionsComorbiditiesType[];
};

/**
 * Determines if a user has a weight-related comorbidity based on their
 * answers to the healthConditions, healthConditionsComorbidities,
 * personalHabits and pregnancy questions in the quiz
 *
 * @param {Partial<QuizStateType>} answers - The user's quiz answers
 * @returns {boolean} true if the user has a weight-related comorbidity, false otherwise
 */
export const hasWeightRelatedComorbidity = (
  answers: Pick<
    QuizStateType,
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
  >,
): boolean => {
  const { personalHabits, pregnancy } = answers;

  const weightRelatedComorbidities = getWeightRelatedComorbidities(answers);

  return (
    weightRelatedComorbidities.length > 0 ||
    personalHabits?.includes('lowMoodDueToWeight') ||
    (!!pregnancy && pregnancy.includes('problemsConceiving'))
  );
};

export const qualifiesForGLP1ProgrammeWithComorbidity = (
  answers: Pick<
    QuizStateType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
    | 'dateOfBirth'
    | 'diabetesStatus'
    | 'gender'
  >,
  locale?: string,
): EligibilityResult => {
  const { weightKg, heightCm } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  /**
   * The Comorbidity criteria is the same as the base GLP1 criteria, except we
   * replace the requirement for a BMI >= 30 with a requirement for a BMI >= 27
   * and < 30, if accompanied by one of the weight-related comorbidities.
   */
  const glp1CriteriaWithoutBMI = getGLP1BaseCriteria(answers, locale).filter(
    criterion => criterion.key !== 'bmi',
  );

  // An array of criteria to determine whether the user qualifies for GLP1
  const criteria: EligibilityCriteria[] = [
    ...glp1CriteriaWithoutBMI,
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteriaWithComorbidity(bmi),
    },
    {
      key: 'comorbidities',
      value: 'none',
      isValid: hasWeightRelatedComorbidity(answers),
    },
  ];

  return reduceEligibilityCriteria(criteria);
};
