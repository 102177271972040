import {
  OldQuizAnswersType,
  HealthConditionsType,
  PregnancyStatusType,
} from '@nextTypes/quiz';
import { getBMI } from '../../../src/utils/numberUtils';
import { displayWeight } from '../../../src/utils/stringUtils';

import { QuizStateType, WeightUnitType } from '@components/Quiz/state/state';
import { calculateAge } from '../../nextUtils/quizUtils';
import { VitalityQuizStateType } from '../VitalityQuiz/state/state';

export const isQuizQuestionComplete = ({
  answers,
  question,
  isMultiSelect = false,
}: {
  answers: Partial<OldQuizAnswersType>;
  question: keyof OldQuizAnswersType;
  isMultiSelect?: boolean;
}): boolean => {
  if (isMultiSelect) {
    return Object.values(Object(answers[question])).some(x => x);
  }
  return answers[question as keyof OldQuizAnswersType] !== undefined;
};

const getLongTermWeightGoalBasedOnBMI = (weight: number, height: number) => {
  let percentageWeightLossGoalLongTerm: null | number = null;
  const currentBMI = getBMI(weight, height) as number;

  if (currentBMI > 35) {
    percentageWeightLossGoalLongTerm = 0.15;
  } else if (currentBMI > 30) {
    percentageWeightLossGoalLongTerm = 0.1;
  } else if (currentBMI > 25) {
    percentageWeightLossGoalLongTerm = 0.07;
  } else if (currentBMI > 23) {
    percentageWeightLossGoalLongTerm = 0.04;
  } else if (currentBMI <= 23) {
    percentageWeightLossGoalLongTerm = 0.03;
  } else {
    percentageWeightLossGoalLongTerm = 0.1;
  }

  const goal =
    Math.round(weight * (1 - percentageWeightLossGoalLongTerm) * 10) / 10;
  return goal;
};

export const getLongTermWeightGoal = ({
  goalKgLongTerm,
  weightKg,
  heightCm,
}: {
  goalKgLongTerm?: number;
  weightKg?: number;
  heightCm?: number;
}): number => {
  let goal = 0;
  if (goalKgLongTerm !== undefined) {
    goal = goalKgLongTerm;
  } else if (weightKg !== undefined && heightCm !== undefined) {
    goal = getLongTermWeightGoalBasedOnBMI(weightKg, heightCm);
  }
  return goal;
};

export const getQuizPredictedWeightLoss = ({
  goalKgLongTerm,
  weightUnit = 'kg',
  weightKg,
  heightCm,
}: {
  goalKgLongTerm?: number;
  weightUnit: WeightUnitType;
  weightKg?: number;
  heightCm?: number;
}): string | 0 => {
  if (!weightKg) {
    return 0;
  }

  return displayWeight(
    weightKg - getLongTermWeightGoal({ goalKgLongTerm, weightKg, heightCm }),
    weightUnit as 'kg' | 'st' | 'lbs',
  );
};

export type EligibilityCriteria = {
  key: string;
  value: string | string[] | number | boolean | null | undefined;
  isValid: boolean;
};

export type EligibilityResult = {
  isEligible: boolean;
  failingCriteria: { [key: string]: string };
};

/**
 * Reduce an array of eligibility criteria to determine if all are successful.
 *
 * @param {EligibilityCriteria[]} criteria - Array of eligibility criteria.
 * @return {EligibilityResult} Whether the answers is eligible and, if not, which criteria they
 * failed.
 */
export const reduceEligibilityCriteria = (
  criteria: EligibilityCriteria[],
): EligibilityResult => {
  // Accumulator keeps track of whether all criteria have been met and any failing criteria
  const { isEligible, failingCriteria } = criteria.reduce(
    (
      { isEligible: criteriaMet, failingCriteria: summary },
      { key, value, isValid },
    ) => {
      if (!isValid) {
        // If the isValid for this criterion fails, update the accumulator
        return {
          isEligible: false,
          failingCriteria: { ...summary, [key]: value },
        };
      }
      // If the isValid for this criterion passes, return the accumulator unchanged
      return { isEligible: criteriaMet, failingCriteria: summary };
    },
    // Initial values for the accumulator
    { isEligible: true, failingCriteria: {} },
  );

  return { isEligible, failingCriteria };
};

export const WEIGHT_LOSS_EXCLUDED_HEALTH_CONDITIONS: HealthConditionsType[] = [
  'Eating disorder',
];

export const WEIGHT_LOSS_EXCLUDED_PREGNANCY_ANSWERS: PregnancyStatusType[] = [
  'pregnant',
];

export const checkForExcludedPregnanacyAnswers = (
  excludedAnswers: string[],
  pregnancyStatus: PregnancyStatusType | PregnancyStatusType[] | undefined,
): boolean => {
  if (pregnancyStatus === undefined) {
    return false;
  }
  if (Array.isArray(pregnancyStatus)) {
    return pregnancyStatus.some(answer => excludedAnswers.includes(answer));
  }
  return excludedAnswers.includes(pregnancyStatus);
};

/**
 * Determine whether a set of quiz answers pass the eligibility criteria for our original
 * weight-loss programme.
 *
 * @param {Partial<OldQuizAnswersType>} answers - Quiz answers.
 * @return {EligibilityResult} Whether the set of answers pass the eligibility criteria and,
 * if not, which criteria they failed.
 */
export const qualifiesForWeightLossProgrammeForOldQuizFlow = (
  answers: Pick<
    OldQuizAnswersType,
    | 'age'
    | 'weightKg'
    | 'heightCm'
    | 'gender'
    | 'pregnancy'
    | 'healthConditions'
  >,
): EligibilityResult => {
  const { age, weightKg, heightCm, pregnancy, healthConditions, gender } =
    answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  // An array of criteria to determine whether the user qualifies for GLP1
  const criteria: EligibilityCriteria[] = [
    {
      key: 'age',
      value: age,
      isValid: !(age === undefined || age < 18 || age > 120),
    },
    {
      key: 'heightCm',
      value: heightCm,
      isValid: heightCm !== undefined,
    },
    {
      key: 'weightKg',
      value: weightKg,
      isValid: weightKg !== undefined,
    },
    {
      key: 'bmi',
      value: bmi,
      isValid: bmi ? bmi > 21 : false,
    },
    { key: 'gender', value: gender, isValid: !!gender },
    {
      key: 'pregnancy',
      value: pregnancy,
      isValid: !(
        gender === 'female' &&
        checkForExcludedPregnanacyAnswers(
          WEIGHT_LOSS_EXCLUDED_PREGNANCY_ANSWERS,
          pregnancy,
        )
      ),
    },
    {
      key: 'healthConditions',
      value: healthConditions,
      isValid: !(
        healthConditions === undefined ||
        healthConditions.some(condition =>
          WEIGHT_LOSS_EXCLUDED_HEALTH_CONDITIONS.includes(condition),
        )
      ),
    },
  ];

  return reduceEligibilityCriteria(criteria);
};

export const qualifiesForWeightLossProgramme = (
  answers: Partial<QuizStateType> | Partial<VitalityQuizStateType>,
): EligibilityResult => {
  const {
    dateOfBirth,
    weightKg,
    heightCm,
    pregnancy,
    healthConditions,
    gender,
  } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);
  const age = calculateAge(
    dateOfBirth
      ? new Date(dateOfBirth)?.toISOString()
      : new Date().toISOString(),
  );
  // An array of criteria to determine whether the user qualifies for GLP1
  const criteria: EligibilityCriteria[] = [
    {
      key: 'dateOfBirth',
      value: age,
      isValid: !(age === undefined || age < 18 || age > 85),
    },
    {
      key: 'heightCm',
      value: heightCm,
      isValid: heightCm !== undefined,
    },
    {
      key: 'weightKg',
      value: weightKg,
      isValid: weightKg !== undefined,
    },
    {
      key: 'bmi',
      value: bmi,
      isValid: bmi ? bmi > 21 : false,
    },
    { key: 'gender', value: gender, isValid: !!gender },
    {
      key: 'pregnancy',
      value: pregnancy,
      isValid: !(
        gender === 'female' &&
        checkForExcludedPregnanacyAnswers(
          WEIGHT_LOSS_EXCLUDED_PREGNANCY_ANSWERS,
          pregnancy,
        )
      ),
    },
    {
      key: 'healthConditions',
      value: healthConditions,
      isValid: !(
        healthConditions === undefined ||
        healthConditions.some(condition =>
          WEIGHT_LOSS_EXCLUDED_HEALTH_CONDITIONS.includes(condition),
        )
      ),
    },
  ];

  return reduceEligibilityCriteria(criteria);
};
