import { AbTestsType, AbTestsMixpanelObj } from '@nextTypes/tracking';

import {
  getMixpanelEventDetails,
  getPartnershipPageViewProperties,
  getQuizFlowPageViewProperties,
  getUserBuckets,
} from './generalUtils';
import { logEventInBraze } from './brazeUtils';
import { QuizStateType } from '../components/Quiz/state/state';
import {
  getGLP1Eligibility,
  getGLP1EligibilityAnswersFromUserState,
} from './glp1/quizSelectors';
import { UserType } from '../types/user';
import { formatAnswersForTracking } from './sanitiseTrackingData';
import { VitalityQuizStateType } from '../components/VitalityQuiz/state/state';

/**
 * Type for the values of the event.properties object.
 *
 * The recursive type allows for nested objects.
 */
type EventPropertyValue =
  | string
  | number
  | Date
  | undefined
  | boolean
  | { [key: string]: EventPropertyValue };

/**
 * Creates Mixpanel object for AB tests
 */
const getAbTestsMixpanelObj = (
  abTests: AbTestsType | null,
): AbTestsMixpanelObj => {
  const abTestsMixpanelObj: AbTestsMixpanelObj = {};

  if (abTests) {
    for (const { testID, variant, description, dateAdded } of Object.values(
      abTests,
    )) {
      abTestsMixpanelObj[`test-${testID}`] = variant.toString();

      if (description) {
        abTestsMixpanelObj[`test-${testID}-description`] = description;
      }
      if (dateAdded) {
        abTestsMixpanelObj[`test-${testID}-date-added`] = dateAdded;
      }
    }
  }

  return abTestsMixpanelObj;
};

/**
 * Updates Mixpanel people properties with quiz results
 */
const updateMixpanelPeopleProperties = (
  priorities: string[],
  recentChallenges: string[],
  healthConditions: string[],
  abTests: AbTestsType | null,
): void => {
  for (const priority of priorities) {
    const name = `priority${priority.charAt(0).toUpperCase() + priority.slice(1)}`;
    window.mixpanel.people.set({ [name]: true });
  }

  for (const recentChallenge of recentChallenges) {
    const name = `recentChallenge${
      recentChallenge.charAt(0).toUpperCase() + recentChallenge.slice(1)
    }`;
    window.mixpanel.people.set({ [name]: true });
  }

  window.mixpanel.people.set({
    priorities,
    recentChallenges,
    healthConditions,
    abTests,
  });
};

/**
 * Sends a tracking event to our database, as well as to Mixpanel
 *
 * n.b. Only to be used when a user is logged in,
 * otherwise the call to /api/fetch will fail
 *
 * @param {string} title - Title of the event
 * @param {Record<string, EventPropertyValue>} [properties] - Additional properties of the event (optional)
 * @param {Date} [date= new Date()] - Date of the event (optional)
 */
export const track = (
  title: string,
  properties: Record<string, EventPropertyValue> = {},
  date: Date = new Date(),
): void => {
  if (!title) {
    return;
  }
  // spread operator only completes a shallow object comparison
  // this means if we mutate mixpanelProperties
  // we'd also mutate the properties object
  // stringifying and parsing does a deep comparison
  const mixpanelProperties: {
    abTests: AbTestsType;
  } = JSON.parse(JSON.stringify(properties));

  const abTestsMixpanelObj: AbTestsMixpanelObj = {};

  if (mixpanelProperties.abTests) {
    for (const { testID, variant } of Object.values(
      mixpanelProperties.abTests,
    )) {
      abTestsMixpanelObj[`test-${testID}`] = variant.toString();
    }
  }

  if (window.mixpanel?.track) {
    window.mixpanel.track(title, {
      ...mixpanelProperties,
      ...abTestsMixpanelObj,
    });
  }

  const body = {
    title,
    properties,
    date,
  };
  fetch('/api/track', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

/**
 * Sends a tracking event to Mixpanel for page view tracking
 *
 * The title it sends to Mixpanel will be 'Viewed xyz page'
 *
 * @param {string} pathname - The path of the URL
 * @param {string} [search] - The search path of the URL (i.e. the query, if it exists) (optional)
 */
export const trackMixpanelPageView = (
  pathname: string,
  search?: string,
): void => {
  if (window.mixpanel?.track) {
    const eventDetails = getMixpanelEventDetails(pathname);

    // look into whether we need to add something here for /quiz
    if (pathname.includes('/partnerships')) {
      const partnershipPageProperties = getPartnershipPageViewProperties(
        pathname,
        search || '',
      );

      window.mixpanel.track('Viewed page', {
        ...partnershipPageProperties,
      });

      return;
    }

    if (pathname.includes('/get-plan')) {
      window.mixpanel.track('Viewed page', {
        ...getQuizFlowPageViewProperties(pathname),
      });

      return;
    }

    for (const event of eventDetails) {
      const {
        category,
        guideTitle,
        pageName,
        referralCode,
        subpage,
        subSections,
        title,
        url,
        ...remainingEventDetails
      } = event;

      // Create an object with all params in the query to be passed back
      let query = {};
      // If there is a query then add the different params into the query object
      if (search) {
        search
          .slice(1)
          .split('&')
          .map(param => {
            const paramObj: Record<string, string> = {};
            paramObj[param.split('=')[0]] = param.split('=')[1];
            query = { ...query, ...paramObj };
            return paramObj;
          });
      }

      window.mixpanel.track(title, {
        'Page Name': pageName,
        'Guide title': guideTitle,
        Category: category,
        Subpage: subpage,
        'Sub Sections': subSections,
        'Referral code': referralCode,
        URL: url,
        ...query,
        ...remainingEventDetails,
      });
    }
  }
};

export const trackQuizBegin = (
  abTests: AbTestsType | null,
  promo: string | undefined,
  userId: string | undefined,
): void => {
  const abTestsMixpanelObj: AbTestsMixpanelObj = {};

  if (abTests) {
    for (const { testID, variant } of Object.values(abTests)) {
      abTestsMixpanelObj[`test-${testID}`] = variant.toString();
    }
  }

  if (window.mixpanel?.track) {
    window.mixpanel.track('Began quiz flow', {
      ...abTestsMixpanelObj,
      ...(promo && { Promo: promo }),
    });

    if (window.location.href.indexOf('emailclick') > -1) {
      window.mixpanel.track('Email link clicked', {
        'Link name': window.location.href.split('emailclick=')[1],
      });
    }
  }

  logEventInBraze('Began quiz flow', userId);
};

export const trackQuizCompleteForQuizFlow = (
  user: UserType | null | undefined,
  answers: QuizStateType | VitalityQuizStateType,
  countryCode: string,
  abTests: AbTestsType | null,
  userId: string | undefined,
  locale: string | undefined,
  shouldSeeMedicationPages: boolean,
): void => {
  if (window.mixpanel?.track) {
    const abTestsMixpanelObj = getAbTestsMixpanelObj(abTests);
    const userBuckets = getUserBuckets();

    const mixpanelObj = {
      ...formatAnswersForTracking(answers),
      ...(locale === 'uk' && {
        isEligibleForGLP1: getGLP1Eligibility({
          // @ts-expect-error ignore the type mismatch for now - this will need a larger fix as part of aligning eligibility checks across the sign-up flows, for new users, logged in users, and returning users as well as consumer vs. vitality.
          answers: getGLP1EligibilityAnswersFromUserState(user),
          locale,
        }),
      }),
      shouldSeeMedicationPages,
      ...abTestsMixpanelObj,
      ...userBuckets,
    };

    window.mixpanel.track('Completed quiz flow', mixpanelObj);
    updateMixpanelPeopleProperties(
      answers.priorities,
      answers.recentChallenges,
      answers.healthConditions,
      abTests,
    );
  }

  logEventInBraze('Completed quiz flow', userId);

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'completed-quiz',
    });
    window.dataLayer.push({
      countryCode,
    });
  }
};

export const trackClickedGetQuiz = ({
  ctaText,
  location,
  product,
  utmCampaign,
  utmMedium,
  utmContent,
  isGlp1,
}: {
  ctaText: string;
  location: string;
  product?: {
    displayName: string;
    amount: number;
    currency: string;
  };
  utmCampaign?: string | undefined;
  utmMedium?: string | undefined;
  utmContent?: string | undefined;
  isGlp1?: boolean;
}): void => {
  if (window.mixpanel?.track) {
    window.mixpanel.track(`Clicked ${isGlp1 ? 'GLP-1' : ''} Quiz CTA`, {
      ctaText, // copy of the button clicked
      location, // component containing CTA
      pageUrl: window.location.pathname?.split('?')?.[0], // url minus referrals/tracking data
      ...(!!product && {
        'Product Name': product.displayName,
        Amount: product.amount,
        Currency: product.currency,
      }),
      ...(!!utmCampaign && { utmCampaign }),
      ...(!!utmMedium && { utmMedium }),
      ...(!!utmContent && { utmContent }),
    });
  }
};
