import {
  GLP1_ELIGIBILITY_CRITERIA,
  getGLP1BaseCriteria,
} from './baseEligibility';

import {
  reduceEligibilityCriteria,
  EligibilityResult,
} from '../../components/Quiz/QuizUtils';
import { QuizStateType } from '../../components/Quiz/state/state';
import { getBMI } from '../../../src/utils/numberUtils';
import { ETHNICITY } from '../../types/quiz';
import { hasWeightRelatedComorbidity } from './comorbidityEligibility';

const BAME_ETHNICITIES: string[] = [
  ETHNICITY.MIXED_WHITE_BLACK_CARIBBEAN,
  ETHNICITY.MIXED_WHITE_BLACK_AFRICAN,
  ETHNICITY.MIXED_WHITE_BLACK_ASIAN,
  ETHNICITY.MIXED_WHITE_ARAB_MIDDLE_EASTERN,
  ETHNICITY.ARAB_MIDDLE_EASTERN,
  ETHNICITY.ASIAN_CHINESE,
  ETHNICITY.ASIAN_INDIAN,
  ETHNICITY.ASIAN_PAKISTANI,
  ETHNICITY.ASIAN_BANGLADESHI,
  ETHNICITY.ASIAN_OTHER,
  ETHNICITY.BLACK_CARIBBEAN,
  ETHNICITY.BLACK_AFRICAN,
  ETHNICITY.BLACK_OTHER,
];

/**
 * @param {string} secondaryEthnicity - The user's answers to the ethnicity quiz question.
 * @returns {boolean} Whether the user has BAME ethnicity.
 */
export const hasBAMEEthnicity = (
  secondaryEthnicity: QuizStateType['secondaryEthnicity'],
): boolean =>
  Boolean(secondaryEthnicity && BAME_ETHNICITIES.includes(secondaryEthnicity));

/**
 * Determines if a BAME user is eligible for the GLP1 programme based on
 * having a BMI equal to or greater than 27.5.
 *
 * @param {number | null} bmi - User's bmi based on quiz answer
 * @returns {boolean} true if the user's bmi is >= 27.5, false otherwise
 */
export const meetsGLP1BMICriteriaWithBAMEEthnicity = (
  bmi: number | null,
): boolean =>
  !!bmi && bmi >= GLP1_ELIGIBILITY_CRITERIA.bmiWithBAMEEthnicity.min;

/**
 * Determines if a user is a BAME user and has the bmi eligibility requirements for a BAME user
 *
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean}
 */
export const meetsGLP1CriteriaWithEthnicityBMI = (
  answers: Pick<QuizStateType, 'secondaryEthnicity' | 'weightKg' | 'heightCm'>,
): boolean => {
  const { weightKg, heightCm, secondaryEthnicity } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  return (
    meetsGLP1BMICriteriaWithBAMEEthnicity(bmi) &&
    hasBAMEEthnicity(secondaryEthnicity)
  );
};

/**
 * Determines if a BAME user with a comorbidity is eligible for the GLP1 programme based on
 * having a BMI equal to or greater than 25.
 *
 * @param {number | null} bmi - User's bmi based on quiz answer
 * @returns {boolean} true if the user's bmi is >= 27.5, false otherwise
 */
export const meetsGLP1BMICriteriaWithBAMEEthnicityAndComorbidity = (
  bmi: number | null,
): boolean =>
  !!bmi &&
  bmi >= GLP1_ELIGIBILITY_CRITERIA.bmiWithBAMEEthnicityAndComorbidity.min;

/**
 * Determines if a BAME user is eligible for the GLP1 programme based on
 * having a BMI above 25 and a weight related comorbidity.
 *
 * @param {number | null} bmi - User's bmi based on quiz answer
 * @returns {boolean} true if the user's bmi is >= 25, false otherwise
 */
export const meetsGLP1CriteriaWithBAMEEthnicityAndComorbidity = (
  answers: Pick<QuizStateType, 'secondaryEthnicity' | 'weightKg' | 'heightCm'>,
): boolean => {
  const { weightKg, heightCm, secondaryEthnicity } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  return (
    meetsGLP1BMICriteriaWithBAMEEthnicityAndComorbidity(bmi) &&
    hasBAMEEthnicity(secondaryEthnicity)
  );
};

/**
 * Determines whether a user qualifies for the GLP1 programme based on:
 * - The base GLP1 criteria
 * - The user's type 2 diabetes lifestyle management
 * - The user's type 2 diabetes medication
 *
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeWithBAMEEthnicity = (
  answers: Pick<
    QuizStateType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
    | 'diabetesStatus'
    | 'dateOfBirth'
    | 'gender'
    | 'secondaryEthnicity'
  >,
  locale?: string,
): EligibilityResult => {
  /**
   * The BAME eligibility criteria is the same as the base and comorbidities
   * criteria, except the BMI minimum at base is 27.5 and the BMI minimum
   * with a weight related comorbidity is 25.
   */
  const glp1CriteriaWithoutBmi = getGLP1BaseCriteria(answers, locale).filter(
    criterion => criterion.key !== 'bmi',
  );

  // For eligibility on the GLP1 programme, BAME users can have a BMI over 27.5
  // instead of over 30
  const bmi = getBMI(answers.weightKg || 0, answers.heightCm || 0);
  const glp1CriteriaWithUpdatedBmiBAMEEthnicity = [
    ...glp1CriteriaWithoutBmi,
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1CriteriaWithEthnicityBMI(answers),
    },
  ];

  const glp1CriteriaWithUpdatedBmiBAMEEthnicityComorbidities = [
    ...glp1CriteriaWithoutBmi,
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1CriteriaWithBAMEEthnicityAndComorbidity(answers),
    },
    {
      key: 'comorbidities',
      value: 'none',
      isValid: hasWeightRelatedComorbidity(answers),
    },
  ];

  const BAMEEligibility = reduceEligibilityCriteria(
    glp1CriteriaWithUpdatedBmiBAMEEthnicity,
  );

  const BAMEEligibilityWithComobidities = reduceEligibilityCriteria(
    glp1CriteriaWithUpdatedBmiBAMEEthnicityComorbidities,
  );

  if (hasWeightRelatedComorbidity(answers)) {
    return BAMEEligibilityWithComobidities;
  }

  return BAMEEligibility;
};
