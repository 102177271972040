import {
  HeightUnitType,
  WeightUnitType as GenericWeightUnitType,
} from '@nextTypes/units';

export type ReferralType = {
  _id: string;
  contract: string;
  signupCode: string;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhoneNumber: string;
  baselineWeightInKg: number;
  heightInM: number;
};

export type WeightUnitType = Omit<GenericWeightUnitType, 'lbs'>;

export type NHSStateType = {
  // AboutYou
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  // Your health
  gender: string;
  age: number | string;
  heightUnit: HeightUnitType;
  heightCm: number;
  heightFT: number;
  heightINCH: number;
  weightUnit: WeightUnitType;
  weightKg: number;
  weightLBS: number;
  weightST: number;
  smokingStatus: string;
  hasBeenReferredToStopSmokingService: boolean;
  healthConditions: string[];
  ethnicity: string;
  disabilityStatus: string | null;
  disabilityType: string | null;
  nhsReferral: ReferralType | null;
  isOnHypoMedication: string;
  // YourGoals
  percentageGoal: number;
  goalWeightKg: number;
  goalWeightLBS: number;
  goalWeightST: number;
  healthBarriers: string;
  regularlyRecord: string;
  usePhoneAndApp: string;
  willingToCommunicate: string;
  readyToBeSuccessful: string;
  nameCommitment: string;
  // FinalDetails
  addressFirstLine: string;
  addressSecondLine: string;
  addressThirdLine: string;
  addressCity: string;
  addressPostCode: string;
  selectedStartDate: string;
  hasValidPhone: boolean | null;
  healthScore: number | null;
  optInQuestionnaire: boolean;
  mobility: string | undefined;
  careForYourself: string | undefined;
  performUsualActivities: string | undefined;
  painOrDiscomfort: string | undefined;
  anxietyOrDepression: string | undefined;
  takingMedication: boolean;
  // ABL questionnaire values
  depression: string | undefined;
  anxiety: string | undefined;
  noPleasure: string | undefined;
  worryControl: string | undefined;
  physicalActivityMinutes: number | null;
  alcoholUnits?: number | null; // deprecated and replaced with fields below
  auditC1AlcoholFrequency?: number | null;
  auditC2AlcoholUnits?: number | null;
  auditC3BingeDrinkingFrequency?: number | null;
};

export const initialState: NHSStateType = {
  nhsReferral: {
    _id: '',
    contract: '',
    signupCode: '',
    email: '',
    firstName: '',
    lastName: '',
    mobilePhoneNumber: '',
    baselineWeightInKg: 0,
    heightInM: 0,
  },

  // AboutYou
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phoneNumber: '',

  // YourHealth
  gender: '',
  age: '',
  heightUnit: 'ft',
  heightCm: 0,
  heightFT: 0,
  heightINCH: 0,
  weightUnit: 'kg',
  weightKg: 0,
  weightLBS: 0,
  weightST: 0,
  smokingStatus: '',
  hasBeenReferredToStopSmokingService: false,
  healthConditions: [],
  ethnicity: '-',
  takingMedication: false,
  disabilityStatus: '',
  disabilityType: '',

  // YourGoals
  percentageGoal: 0,
  goalWeightKg: 0,
  goalWeightLBS: 0,
  goalWeightST: 0,
  healthBarriers: '',
  regularlyRecord: '',
  usePhoneAndApp: '',
  willingToCommunicate: '',
  readyToBeSuccessful: '',
  nameCommitment: '',
  hasValidPhone: null,

  // FinalDetails
  addressFirstLine: '',
  addressSecondLine: '',
  addressThirdLine: '',
  addressCity: '',
  addressPostCode: '',
  selectedStartDate: '',
  isOnHypoMedication: '',

  // NDPP Questionnaire
  healthScore: null,
  optInQuestionnaire: false,
  mobility: undefined,
  careForYourself: undefined,
  performUsualActivities: undefined,
  painOrDiscomfort: undefined,
  anxietyOrDepression: undefined,

  // ABL questionnaire values
  depression: undefined,
  anxiety: undefined,
  worryControl: undefined,
  noPleasure: undefined,
  physicalActivityMinutes: null,
  auditC1AlcoholFrequency: null,
  auditC2AlcoholUnits: null,
  auditC3BingeDrinkingFrequency: null,
};
